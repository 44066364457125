<template>
<loader v-bind="{ loading }">
    <columns>
      <column>
        <page-heading heading="New Equipment Classification">
            Create an Equipment Classification to categorise your equipment, capture vital metadata and <em>optionally</em> assign this classification to checklist templates.
        </page-heading>
      </column>
      <column class="is-narrow">
        <router-link :to="{
          name: 'equipment-classifications'
        }">Back</router-link>
      </column>
    </columns>

    <form>
        <columns>
            <column>
                <text-input
                    classes="is-medium is-rounded"
                    placeholder="Eg. Delivery Van"
                    required
                    :error="$root.errors.name"
                    :value="equipment_classification.name"
                    @input="updateName">
                    Name
                </text-input>
            </column>
        </columns>
        <team-segmentation />
        <meta-data-builder />
        <checklist-assignment />
        <columns>
            <column>
                <submit-button
                    class="is-rounded is-medium mt-3"
                    :working="working"
                    @submit="createClassification">
                    Save
                </submit-button>
            </column>
        </columns>
    </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ChecklistAssignment from './partials/ChecklistAssignment.vue'
import MetaDataBuilder from './partials/MetaDataBuilder.vue'
import TeamSegmentation from './segmentation/TeamSegmentation.vue'

export default {
    
    components: {
        MetaDataBuilder,
        ChecklistAssignment,
        TeamSegmentation
    },

    data: () => ({
        loading: false,
        working: false,
    }),

    async beforeCreate() {
        await this.$store.dispatch('team/loadTeamList')
    },

    beforeDestroy() {
        this.clearEquipmentClassification()
    },

    methods: {
        ...mapMutations('equipmentClassification', [
            'updateName',
            'clearEquipmentClassification'
        ]),
        createClassification() {
            this.working = true
            this.$store.dispatch('equipmentClassification/create').then(() => {
                this.$toast.success('Classification created')
                this.$router.push('equipment-classifications')
                this.working = false
            }).catch(() => this.working = false)
        }
    },

    computed: {
        ...mapGetters('equipmentClassification', [
            'equipment_classification'
        ])
    }

}
</script>